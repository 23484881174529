import styled from "styled-components";

export const TitleText = styled.div`
    font-family: "Open Sans", sans-serif;
    font-size: 20pt;
    color: #663399;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
`;

export const ServiceText = styled.div`
    font-family: "Calibri Light", sans-serif;
    font-size: 16pt;
    text-transform: uppercase;
    color: #8e8e8e;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
`;

export const Text = styled.div`
    font-family: "Calibri Light", sans-serif;
    font-size: 14pt;
    color: #6f6f6f;
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
    line-height: 1.6em;
    
`;