import React, {Component} from 'react';
import crowd from '../assets/homepage/homepage-crowd.svg';
import crowd_mobile from '../assets/homepage/homepage_crowd_mobile.svg';
import styled from 'styled-components';
import ReactTypingEffect from 'react-typing-effect';
import {ServiceText, Text, TitleText} from '../components/elements/Text';
import ScrollDown from "../components/elements/ScrollDown";
//import Carousel3D from "../utils/3dcarousel/Carousel3D";
import {NavLink} from "react-router-dom";
import ColoredIcon from "../components/elements/ColoredIcon";
import {Service, FlexRow} from "../components/elements/Services";
import {FaEnvelope, FaPhoneAlt} from "react-icons/all";

const Slide = styled.div`
    width: 100%;
    height: 100%;
    
    img{
        object-fit: cover;
        width: 100%;
        height: 80%;
    }
    div{
        font-size: 17pt;
        font-family: "Calibri Light", sans-serif;
        height: 20%;
    }
`;

// noinspection JSUnusedLocalSymbols eslint-disable-next-line
const slides = [
  {
    content: <Slide><img src="https://picsum.photos/800/800/?random" alt="1"/>
      <div>Almás Pista</div>
    </Slide>
  },
  {
    content: <Slide><img src="https://picsum.photos/800/800/?random" alt="1"/>
      <div>Almás Pista</div>
    </Slide>
  },
  {
    content: <Slide><img src="https://picsum.photos/800/800/?random" alt="1"/>
      <div>Almás Pista</div>
    </Slide>
  },
  {
    content: <Slide><img src="https://picsum.photos/800/800/?random" alt="1"/>
      <div>Almás Pista</div>
    </Slide>
  },
  {
    content: <Slide><img src="https://picsum.photos/800/800/?random" alt="1"/>
      <div>Almás Pista</div>
    </Slide>
  },
  {
    content: <Slide><img src="https://picsum.photos/800/800/?random" alt="1"/>
      <div>Almás Pista</div>
    </Slide>
  },
];

const PositionedScroller = styled.div`
    @media screen and (max-width: 600px){
        width: 100%;
        position:absolute;
        display: flex;
        align-items: center;
        top: 90%;
        flex-direction: row;
        justify-content: center;
    }
`;

const CrowdImage = styled.img`
    height: 98vh;
    align-self: flex-end;
    overflow: hidden;
    
    @media screen and (max-width: 1200px) and (min-width: 1001px){
      align-self: flex-start;
      margin-left: 10px;
    }
    
    @media screen and (max-width: 1000px) and (min-width: 300px){
      width: 100%;
      right: 0;
      position:absolute;
      top: 5%;
    }
`;

const HomePageContainer = styled.div`
    position: relative;
    top:-60px;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    overflow: hidden;
    
    @media screen and (max-width: 600px){
        display: block;
        height: 100vh;
        overflow: unset;
        top: 0;
    }
    
    .contact-infos>a{
        text-decoration: none;
        color: rebeccapurple;
        font-family: "Raleway Light", sans-serif;
        font-size: 16pt;
        margin-left: 30px;
        
        @media screen and (max-width: 600px){
            font-size: 11pt;
            margin-left: 15px;
        }
    }
    
    .contact-infos{
        position:absolute;
        top: 130px;
        left:10%;
        width: 80%;
        @media screen and (max-width: 600px){
          top:75px;
          width: 100%;
          left: 0;
        }
    }
`;

const BigText = styled.h1`
    font-family: "Open Sans Light", sans-serif;
    font-size: 30pt;
    color: #75c7cc;
    position:absolute;
    top: 20%;
    left:4%;
    width: 80%;
    text-align: left;
    
    @media screen and (max-width: 1200px) and (min-width: 1001px){
      font-size: 25pt;
    }
    
    @media screen and (max-width: 1000px) and (min-width: 601px){
      font-size: 20pt;
    }
    
    @media screen and (max-width: 600px) and (min-width: 300px){
      font-size: 16pt;
      width: 90%;
      left: 5%;
      text-align: center;
      top: 20%;
    }
    
`;

const TypedText = styled(ReactTypingEffect)`
      color: rebeccapurple;
      display: inline-block;
      font: inherit;
`;

export const ContactButton = styled(NavLink)`
    padding: 10px 25px;
    font-size: 17pt;
    background-color: white;
    border: 1px solid rebeccapurple;
    border-radius: 25px;
    margin: 10px auto;
    font-family: "Open Sans", sans-serif;
    color: rebeccapurple;
    width: fit-content;
    text-decoration: none;
    
    &:active, &:hover{
       border-color: rebeccapurple;
       color: white;
       background: rebeccapurple;    
    }
`;

export default class Homepage extends Component {
  textArray = ["videóink", "grafikáink", "cikkeink", "egyedi módszerünk"];

  state = {
    slideWidth: 300,
    slideHeight: 200,
  };

  resizeSlide() {
    let newWidth;
    let newHeight;
    if (window.innerWidth < 601) {
      newWidth = window.innerWidth / 3 * 2;
      // noinspection JSSuspiciousNameCombination
      newHeight = newWidth;
    } else if (window.innerWidth < 1201) {
      newWidth = 450;
      newHeight = 250;
    } else {
      newWidth = 600;
      newHeight = 370;
    }

    this.setState({
      slideWidth: newWidth,
      slideHeight: newHeight,
    });
  }

  componentDidMount() {
    window.onresize = () => this.resizeSlide();
    this.resizeSlide();
  }

  render() {
    return (
      <div>
        <HomePageContainer>
          <CrowdImage src={window.innerWidth < 500 ? crowd_mobile : crowd}/>
          <PositionedScroller><ScrollDown/></PositionedScroller>
          <div className={"contact-infos"}>
            <a href={"tel:+36301234567"}>
              <FaPhoneAlt/> +36 30 616 0014
            </a>
            <a href={"mailto:hello@valiantnetworks.hu"}>
              <FaEnvelope/> hello@valiantnetworks.hu
            </a>
          </div>
          <BigText>
            Velünk egy karnyújtással elérheti<br/>
            a kívánt célközönséget {window.innerWidth < 500 && <br/>}<TypedText speed={70} typingDelay={500}
                                                                                eraseDelay={500}
                                                                                text={this.textArray}/><br/>
            segítségével.
          </BigText>
        </HomePageContainer>
        <div style={{width: "70%", margin: "10px auto"}}>
          <TitleText>Egy Egész Csapat Áll a Rendelkezésedre</TitleText>
          <Text>
            Egy átlagos marketing szakember felvétele bruttó 550.000 Ft-jába kerül a cégednek havonta. De Ő
            egymaga még nem képes ellátni a legalapvetőbb feladatokat sem, szüksége van még Fotósokra,
            Filmesekre, Webfejlesztőkre, Social Media Specialistára, Google Ads szakértőre. De van egy jó
            hírünk! <b>NEM</b> Neked kell összeállítanod ezt a drága csapatot a saját költségedre!<br/>
            Mi rendelkezésedre bocsátjuk a saját profi csapatunkat! <b>Egy csapatnyi profi szakember</b> fog
            dolgozni
            a projekteiden!
          </Text>
        </div>
        <ContactButton to={"/kapcsolat"}>Árajánlatot kérek</ContactButton>
        {/*<div style={{width: "80%", margin: "40px auto 100px auto"}}>*/}
        {/*    <TitleText>A Csapat</TitleText>*/}
        {/*    <Carousel3D height={this.state.slideHeight} width={this.state.slideWidth} slides={slides}/>*/}
        {/*</div>*/}
        <FlexRow>
          <Service>
            <ColoredIcon className="fas fa-comment-alt"/>
            <ServiceText>KOMMUNIKÁCIÓ</ServiceText>
            <Text>
              A kommunikáció az egyik legfontosabb képesség akár online akár személyesen történik. Akkor
              tud tökéletesen működni, ha pontosan tudjuk, hogyan kell szóljunk ahhoz, akihez szólni
              szeretnénk, épp emiatt nagyon fontos az ideális célközönség jó előre való meghatározása!
            </Text>
          </Service>
          <Service>
            <ColoredIcon className="fab fa-facebook-f"/>
            <ServiceText>SOCIAL MEDIA</ServiceText>
            <Text>
              A kommunikáció az egyik legfontosabb képesség akár online akár személyesen történik. Akkor
              tud tökéletesen működni, ha pontosan tudjuk, hogyan kell szóljunk ahhoz, akihez szólni
              szeretnénk, épp emiatt nagyon fontos az ideális célközönség jó előre való meghatározása!
            </Text>
          </Service>
          <Service>
            <ColoredIcon className="fas fa-code"/>
            <ServiceText>Weboldal készítés</ServiceText>
            <Text>
              A kommunikáció az egyik legfontosabb képesség akár online akár személyesen történik. Akkor
              tud tökéletesen működni, ha pontosan tudjuk, hogyan kell szóljunk ahhoz, akihez szólni
              szeretnénk, épp emiatt nagyon fontos az ideális célközönség jó előre való meghatározása!
            </Text>
          </Service>
          <Service>
            <ColoredIcon className="fas fa-bullhorn"/>
            <ServiceText>Hirdetéskezelés</ServiceText>
            <Text>
              A kommunikáció az egyik legfontosabb képesség akár online akár személyesen történik. Akkor
              tud tökéletesen működni, ha pontosan tudjuk, hogyan kell szóljunk ahhoz, akihez szólni
              szeretnénk, épp emiatt nagyon fontos az ideális célközönség jó előre való meghatározása!
            </Text>
          </Service>
        </FlexRow>
      </div>
    );
  }
}
