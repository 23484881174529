import React, {Component} from 'react';
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";

const Wrapper = styled.div`
  margin-top: 10px;
  width: 27%;
  height: 300px;
  position:relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-family: "Raleway Light",sans-serif;
  font-size: 25pt;
  
  & > img{
    width: auto;
    max-width: 95%;
    height: 98%;
    object-fit: contain;
  }
  
  &>div:nth-child(2){
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    background-color: rgba(153,22,230,0.84);
    color: rgb(255,255,255);
    font-family: "Raleway", sans-serif;
    font-size: 22pt;
    font-weight: 800;
    
    //display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: none;
  }
  
  &:hover{
      &>div:nth-child(2){
        display: flex;
      }
      cursor: pointer;
  }
  
  @media screen and (max-width: 1000px) and (min-width: 501px){
    width: 45%;
  }
  
  @media screen and (max-width: 500px){
    height: 200px;
    width: 45%;
    &>div:nth-child(2){
      font-size: 16pt;    
    }
  }
`;

export default class Reference extends Component {

  state = {
    showVideo: 0,
  };

  handleClicked() {
    if (this.props.videos && this.props.videos.length > 0) {
      EventSystem.publish('open_vimeo', {videos: this.props.videos})
    }
  }

  render() {
    return (
      <Wrapper onClick={() => this.handleClicked()}>
        {this.props.logo &&
        <img alt={"logo_" + this.props.name} src={this.props.logo}/>
        }
        {!this.props.logo &&
        <div>{this.props.name}</div>
        }
        <div>{this.props.name}</div>
      </Wrapper>
    )
  }
}
