import React from "react";
import {toast, ToastContainer} from "react-toastify";
import {css} from "glamor";
import "react-toastify/dist/ReactToastify.css";

const ConfiguredToastContainer = () => (
  <ToastContainer
    autoClose={3000}
    draggablePercent={50}
    position={toast.POSITION.TOP_RIGHT}
    toastClassName={css({
      background: "#ffffff",
      color: "#1f1f1f",
      boxShadow: "0 0 3px 1px #121212",
    })}
    progressClassName={css({
      height: "5px",
      background: "#9954fd",
    })}
  />
);

export default ConfiguredToastContainer;
