import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import Vimeo from "@u-wave/react-vimeo";
import EventSystem from "../../utils/EventSystem";
import {FaTimes} from "react-icons/all";

const Wrapper = styled.div`
  position: fixed;
  top:0;
  left: 0;
  height: 0;
  width: 100vw;
  z-index: 1000;
  
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  background-color: transparent;
  
  transition: background-color 100ms ease-in-out, height 100ms ease-in-out;
  
  ${({show}) => show === true && css`
    background-color: rgba(0,0,0,0.1);
    height: 100vh;
  `}
`;

const VimeoWrapper = styled.div`
    width: 90%;
    height: 90%;
    
    position: relative;
    
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    display: ${({display}) => display};
    z-index: 1000;
    border-radius: 4px;
    
    background-color: white;
    box-shadow: 0 0 10px 2px dimgray;
    cursor: auto;
    
    & > svg{
      color: darkmagenta;
      position:absolute;
      top:20px;
      right:20px;
      &:hover{
        cursor: pointer
      }
    }
    
    @media screen and (max-width: 600px){
      height: auto;
      width: 99%;
      padding: 30px 0 30px 0;
      min-height: 45vh;
      
      & > svg{
        top: 10px;
        right: 7px;
      }
    }
`;

const VimeoEmbed = styled(Vimeo)`
  width: 100% !important;
  height: 100% !important;
  
  & #player, & iframe{
    width: 100% !important;
    height: 100% !important;
  }
`;


export default class VimeoPlayer extends Component {
  state = {
    showVideo: false,
    videos: [],
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", e => this.handleKeyDown(e));
  }

  componentDidMount() {
    window.addEventListener("keydown", e => this.handleKeyDown(e));
    EventSystem.subscribe('open_vimeo', ({videos}) => {
      this.setState({
        videos,
        showVideo: videos && videos.length > 0
      });
    })
  }

  clickOnVimeoWrapper(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleCloseClick() {
    this.setState({showVideo: 0, videos: []});
  }

  handleKeyDown(e) {
    if (e.key === "Escape") {
      this.handleCloseClick();
    }
  }

  render() {
    return (
      <Wrapper show={this.state.showVideo} onClick={() => this.handleCloseClick()} onWheel={e => e.stopPropagation()}>
        <VimeoWrapper onClick={(e) => this.clickOnVimeoWrapper(e)}
                      display={this.state.showVideo === true ? 'flex' : 'none'}>
          <FaTimes onClick={() => this.handleCloseClick()}/>
          {this.state.videos && this.state.videos[0] &&
          <VimeoEmbed
            video={this.state.videos[0].vimeoID}
            autopause
            autoplay
            paused={this.state.showVideo === false}
          />
          }
        </VimeoWrapper>
      </Wrapper>
    )
  }
}
