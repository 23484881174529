import React, {Component} from 'react'
import {PageWrapper} from "../components/elements/PageWrapper";
import styled from "styled-components";

const S = styled(PageWrapper)`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40pt;
  color: #282c34;
  font-family: 'Segoe UI Semilight', sans-serif;
  
  @media screen and (max-width: 1000px){
    font-size: 25pt;
  }
`;

class ErrorPage extends Component {
    render() {
        return (
            <S>
                A kért oldal nem található! :(
            </S>
        );
    }
}

export default ErrorPage;