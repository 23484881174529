import React, {Component} from 'react'
import {PageWrapper} from "../components/elements/PageWrapper";
import styled from "styled-components";
import {FlexRow, Service} from "../components/elements/Services";
import ColoredIcon from "../components/elements/ColoredIcon";
import {ServiceText, Text} from "../components/elements/Text";
import {ContactButton} from "./Homepage";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    
    width: 98%;
    margin: 20px auto;
    h1{
      font-family: "Segoe UI", sans-serif;
      font-size: 30pt;
      color: rebeccapurple;
    }
`;

class SzolgaltatasokPage extends Component {
  render() {
    return (
      <PageWrapper>
        <Container>
          <h1>Szolgáltatásaink</h1>
          <ContactButton to={"/kapcsolat"}>Árajánlatot kérek</ContactButton>
          <FlexRow>
            <Service>
              <ColoredIcon className="fas fa-comment-alt"/>
              <ServiceText>KOMMUNIKÁCIÓ</ServiceText>
              <Text>
                A kommunikáció az egyik legfontosabb képesség akár online akár személyesen történik.
                Akkor
                tud tökéletesen működni, ha pontosan tudjuk, hogyan kell szóljunk ahhoz, akihez szólni
                szeretnénk, épp emiatt nagyon fontos az ideális célközönség jó előre való meghatározása!
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fab fa-facebook-f"/>
              <ServiceText>SOCIAL MEDIA</ServiceText>
              <Text>
                Manapság már elengedhetetlen, hogy jelen legyél a közösségi médiában és aktívan
                kommunikálj a különböző social média csatonákon. Létrehozzuk és folyamatosan gondozzuk
                az általad kiválasztott social media felületeket.
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fas fa-code"/>
              <ServiceText>Weboldal készítés</ServiceText>
              <Text>
                Bemutatkozó, Céges weboldal vagy komplex Webáruház, esetleg egy jól konvertáló landing
                oldalra van szükséged? Bármi is legyen az elképzelésed, oszd meg velünk és segítünk!
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fas fa-bullhorn"/>
              <ServiceText>Hirdetéskezelés</ServiceText>
              <Text>
                Legyen szó akár Google Adsről akár Facebook, Instagram hirdetésekről, ezeknek az
                elemzéséhez valamint frissítéséhez és javításához szükség van adatvezérelt
                marketingre és pár jól működő, bevált stratégiára ami megalapozza a folyamatos
                eredményeket és a rövid, közép és hosszútávú sikereidet.
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fas fa-pen-fancy"/>
              <ServiceText>Kreatív szövegírás</ServiceText>
              <Text>
                Több száz és több ezer hirdetést teszteltünk már végig a munkánk során - Tudjuk, hogy
                kell pszichológiai alapokra támaszkodva a figyelmet felkelteni és a legjobb hatát
                elérni, illetve ezeket kiegészítve milyen kreatívval kaphatnánk a legjobb eredményeket.
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fas fa-tint"/>
              <ServiceText>Grafika / Design</ServiceText>
              <Text>
                A Grafika és a Design, valamint a céges arculat elengedhetetlen eszközei a vállalkozásod
                kommunikációjának. Ez az első amivel találkozni fognak a látogatóid és az első
                benyomásukat a vizuális élményeik alapján fogják kialakítani. Pont ezért létfontosságú,
                hogy az arculat és a design a weboldaltól a hirdetési kreatívokig összepasszoljon és
                megfelelően tudják átadni céged üzenetét, filozófiáját és értékeit. Egy kreatív vizuális
                elem sokkal mélyebben beépül az ember memóriájába, így hosszútávon lehet rá építeni.
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fas fa-camera"/>
              <ServiceText>Fotó / Videó</ServiceText>
              <Text>
                A képes tartalom egyik legmeghatározóbb és kézzelfeghatóbb elem amivel egy szempillantás
                alatt fel lehet kelteni az érdeklődők figyelmét valami iránt. Ez csak akkor tud működni,
                ha profi eszközökkel profi minőségi anyagokat gyártunk a célközönség számára!<br/>
                Egy kép többet mond 1000 szónál, de egy rossz kép még annál is többet!
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fas fa-chart-line"/>
              <ServiceText>Kampánykezelés</ServiceText>
              <Text>
                Az inbound stratégiádat erősítjük profi mérhető Facebook és Instagram kampányokkal, és
                változatos hirdetésekkel turbózzuk fel. Ezzel is gyorsítva az eredményeket. A hirdetés a
                stratégia rövidtávú részének alapja.
              </Text>
            </Service>
            <Service>
              <ColoredIcon className="fab fa-readme"/>
              <ServiceText>Tartalomgyártás</ServiceText>
              <Text>
                Kiemelkedni a tömegből a manapság uralkodó nagy reklámzajban csak kiemelkedő minőségű -
                releváns - egyedi tartalommal (blogposzt, e-book, videók, képek... stb.) lehet. Ez nem
                sprint, ez maraton, ez a hosszútávú Inbound marketing stratégia alapköve.
              </Text>
            </Service>
          </FlexRow>
          <ContactButton to={"/kapcsolat"}>Árajánlatot kérek</ContactButton>
        </Container>
      </PageWrapper>
    );
  }
}

export default SzolgaltatasokPage;
