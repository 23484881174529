import React, {Component} from 'react'
import './Header.scss';
import {NavLink} from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import HamburgerMenuIcon from "./HamburgerMenuIcon";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            windowWidth: window.innerWidth,
            windowHeigsht: window.innerHeight,
            scrolled: false
        };
    }

    menuStateChanged(opened) {
        this.setState({menuOpened: opened});
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                this.setState({scrolled: true});
            } else {
                this.setState({scrolled: false});
            }
        })
    }

    render() {
        return (
            <div className={"navigation-bar " + (this.state.scrolled ? "scrolled" : "")}>
                <NavLink className={"logo-navlink"} to={"/"}>
                    <HeaderLogo/>
                </NavLink>
                <div className={"navigation-menu " + (this.state.menuOpened ? 'opened' : 'closed')}>
                    <NavLink to={"/"}>
                        <div className={"navigation-menu-item"}>Főoldal</div>
                    </NavLink>
                    <NavLink to={"/kapcsolat"}>
                        <div className={"navigation-menu-item"}>Kapcsolat</div>
                    </NavLink>
                    {/*<NavLink to={"/rolunk"}>*/}
                    {/*    <div className={"navigation-menu-item"}>Rólunk</div>*/}
                    {/*</NavLink>*/}
                    <NavLink to={"/referencia"}>
                        <div className={"navigation-menu-item"}>Referencia</div>
                    </NavLink>
                    <NavLink to={"/szolgaltatasok"}>
                        <div className={"navigation-menu-item"}>Szolgáltatásaink</div>
                    </NavLink>
                    {/*
                        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown>
                        */}
                </div>
                <HamburgerMenuIcon menuStateChanged={this.menuStateChanged.bind(this)}/>
            </div>
        );
    }
}

export default Header;