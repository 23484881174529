import React, {Component} from 'react'
import {PageWrapper as PageWrapperOrg} from "../components/elements/PageWrapper";
import styled from "styled-components";
import Reference from "../components/elements/Reference";

import citySightseeingLogo from '../assets/refs/city_sightseeing.jpg';
import credithomeLogo from '../assets/refs/credithome.png';
import gringosAmigosLogo from '../assets/refs/gringos_amigos.jpg';
import hungexpoLogo from '../assets/refs/hungexpo.jpg';
import formula1Logo from '../assets/refs/f1-logo2.jpg';
import oktogonLogo from '../assets/refs/oktogon-logo.jpg';
import jokaiDentalLogo from '../assets/refs/jokaidental_logo.png';
import lujoyLogo from '../assets/refs/lujoy_logo.jpg';
import marriottLogo from '../assets/refs/marriott.png';
import mellowMoodHotelsLogo from '../assets/refs/mellow_mood_hotels.png';
import parisiUdvarLogo from '../assets/refs/parisi_udvar.jpg';
import theGridClubLogo from '../assets/refs/thegridclub.png';
import csodalampaLogo from '../assets/refs/csodalampa.jpg';
import casaDoroLogo from '../assets/refs/casa_pomo_doro_logo.jpg';
import teamBeastLogo from '../assets/refs/team-beast.png';

import citySightSeeingVisegradLogo from '../assets/refs/city_sightseeing_visegrad.png';
import citySightSeeingNyiregyLogo from '../assets/refs/city_sightseeing_nyiregy.png';

import apeReginaLogo from '../assets/refs/ape_regina.jpg';
import bohemtanyaRestaurantLogo from '../assets/refs/bohemtanya_restaurant.png';
import cleanerCenterLogo from '../assets/refs/cleaner_center_ logo.jpg';
import cocktailBusBudapestLogo from '../assets/refs/cocktail_bus_budapest.png';
import creamaLogo from '../assets/refs/creama.png';
import expressPCRLogo from '../assets/refs/express-pcr.png';
import gustiLogo from '../assets/refs/gusti.png';
import i55Logo from '../assets/refs/i55.jpeg';
import intercontinentalLogo from '../assets/refs/intercontinental.png';
import kissLaszloLogo from '../assets/refs/kiss_laszlo.jpg';
import lafortezzaLogo from '../assets/refs/lafortezza.jpg';
import pilvaxLogo from '../assets/refs/pilvax.jpg';
import pizzaPapitoLogo from '../assets/refs/pizza_papito.png';
import postakocsiLogo from '../assets/refs/postakocsi_italiano.jpg';
import siRestaurantLogo from '../assets/refs/si_restaurant.jpeg';
import thaiheartLogo from '../assets/refs/thaiheart.jpg';
import trofeaLogo from '../assets/refs/trofea.png';
import violetODLogo from '../assets/refs/violet_od.png';
import visitJordanLogo from '../assets/refs/visit_jordan.png';


// import biocreditLogo from '../assets/refs/biocredit.png';
import selfieWorldLogo from '../assets/refs/selfieworldbudapest.jpg';
import euramaLogo from '../assets/refs/EUrama-logo-color.png';

const PageWrapper = styled(PageWrapperOrg)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RefsWrapper = styled.div`
  padding: 30px 0;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  
  @media screen and (max-width: 600px){
    width: 95%;
  }
`;

const VimeoLink = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > a{
    text-decoration: none;
    font-size: 16pt;
    color: #71378c;
    padding: 10px 25px;
    border: 2px solid #71378c;
    border-radius: 30px;
    
    width: fit-content;
    
    &:hover{
      background-color: #71378c;
      color: white;
      cursor: pointer;
    }
  }
`;

class ReferenciakPage extends Component {

  state = {
    reflist: [
      {
        name: 'City SightSeeing',
        logoSrc: citySightseeingLogo,
        videos: [
          {vimeoID: 390787490},
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'City SightSeeing Nyiregyháza',
        logoSrc: citySightSeeingNyiregyLogo,
        videos: [
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'City SightSeeing Visegrád',
        logoSrc: citySightSeeingVisegradLogo,
        videos: [
          //{vimeoID: 390787490},
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'HungExpo',
        logoSrc: hungexpoLogo,
        videos: [
          {vimeoID: 496591544},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Formula 1',
        logoSrc: formula1Logo,
        videos: [
          {vimeoID: 393143816},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Marriott Hotels',
        logoSrc: marriottLogo,
        videos: [
          {vimeoID: 440341981},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Párisi Udvar',
        logoSrc: parisiUdvarLogo,
        videos: [
          {vimeoID: 497314348},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Mellow Mood Hotels',
        logoSrc: mellowMoodHotelsLogo,
        videos: [
          {vimeoID: 497310614},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Csodalámpa alapítvány',
        logoSrc: csodalampaLogo,
        videos: [
          {vimeoID: 390803065},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Gringos Amigos',
        logoSrc: gringosAmigosLogo,
        videos: [
          {vimeoID: 497314978},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Oktogon Medical',
        logoSrc: oktogonLogo,
        videos: [
          {vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'LUJOY',
        logoSrc: lujoyLogo,
        videos: [
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'The Grid Club',
        logoSrc: theGridClubLogo,
        videos: [
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Visit Jordan',
        logoSrc: visitJordanLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Intercontinental Budapest',
        logoSrc: intercontinentalLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'La Fortezza',
        logoSrc: lafortezzaLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'i55',
        logoSrc: i55Logo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Trófea',
        logoSrc: trofeaLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Ape Regina',
        logoSrc: apeReginaLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Pilvax kávéház',
        logoSrc: pilvaxLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Bohémtanya Restaurant',
        logoSrc: bohemtanyaRestaurantLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Creama',
        logoSrc: creamaLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Pizza Papito',
        logoSrc: pizzaPapitoLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Postakocsi Italiano',
        logoSrc: postakocsiLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Simply Italian',
        logoSrc: siRestaurantLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Cleaner Center',
        logoSrc: cleanerCenterLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Cocktail Bus Budapest',
        logoSrc: cocktailBusBudapestLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Express-PCR',
        logoSrc: expressPCRLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Gusti',
        logoSrc: gustiLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Kis László',
        logoSrc: kissLaszloLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'thaiheart',
        logoSrc: thaiheartLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Violet OD',
        logoSrc: violetODLogo,
        videos: [
          //{vimeoID: 496591766},
        ],
        size: 1,
        //text: '',
      },
      {
        name: "Team Beast",
        logoSrc: teamBeastLogo,
        videos: [
          {vimeoID: 444561827},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Credithome',
        logoSrc: credithomeLogo,
        videos: [
          {vimeoID: 390915266},
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'Jókai Dental',
        logoSrc: jokaiDentalLogo,
        videos: [
          {vimeoID: 497662067},
        ],
        size: 1,
        //text: '',
      },
      {
        name: "Casa Pomo D'Oro",
        logoSrc: casaDoroLogo,
        videos: [
          // {vimeoID: 390787490},
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'SelfieWorld',
        logoSrc: selfieWorldLogo,
        videos: [
          {vimeoID: 393144035},
        ],
        size: 1,
        //text: '',
      },
      {
        name: 'EUrama',
        logoSrc: euramaLogo,
        videos: [
          // {vimeoID: 390787490},
        ],
        size: 1,
        //text: '',
      },
    ],
  };

  render() {
    return (
      <PageWrapper>
        <RefsWrapper>
          {this.state.reflist.map((r, i) =>
            <Reference
              key={i}
              name={r.name}
              logo={r.logoSrc}
              videos={r.videos}
              size={r.size}
            />
          )}
        </RefsWrapper>
        <VimeoLink>
          <a target={"_blank"} href={"https://vimeo.com/user108522316"}>További munkáink</a>
        </VimeoLink>
      </PageWrapper>
    );
  }
}

export default ReferenciakPage;
