import styled, {keyframes} from 'styled-components';
import React, {Component} from "react";

const ScrollDowns = styled.div`
    margin: 0;
`;

const Mousey = styled.div`
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid rebeccapurple;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
`;

const scroll = keyframes`
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
`;

const Scroller = styled.div`
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: rebeccapurple;
    animation-name: ${scroll};
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
`;

export default class ScrollDown extends Component {
    render() {
        return (
            <ScrollDowns>
                <Mousey>
                    <Scroller/>
                </Mousey>
            </ScrollDowns>
        );
    }
};