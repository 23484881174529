import React, {Component} from 'react';
import './HamburgerMenuIcon.css'
import EventSystem from '../../utils/EventSystem';

class HamburgerMenuIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {menuOpened: (this.props.open === true)};
    }

    toggleMenu() {
        this.setMenuState(!this.state.menuOpened);
    }

    updateDimensions() {
        if (window.innerWidth > 951)
            this.setMenuState(false)
    }

    setMenuState(open) {
        this.setState({menuOpened: open});
        this.props.menuStateChanged(open);
    }

    componentDidMount() {
        EventSystem.subscribe('urlChanged', () => {
            this.setMenuState(false);
        });

        window.addEventListener("resize", () => this.updateDimensions.bind(this));
        window.addEventListener("orientationchange", () => this.setMenuState(false));
        this.setState({menuOpened: (this.props.open === true)});
    }

    render() {
        return (
            <div className={"navigation-hamburger-menu-container"}>
                <div className={"nav-hamburger-menu-icon " + (this.state.menuOpened ? "open" : "")}
                     onClick={() => this.toggleMenu()}>
                    <span/>
                    <span/>
                    <span/>
                </div>
            </div>
        );
    }
}

export default HamburgerMenuIcon;