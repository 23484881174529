import styled from "styled-components";

export const Service = styled.div`
  width: 500px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-self: center;
  margin: 10px 25px 10px 25px;
  @media screen and (max-width: 600px){
    width: 90%;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
`;
