import React, {Component} from "react";
import './Footer.css'
import {NavLink} from "react-router-dom";
import {FaEnvelope, FaFacebookF, FaPhoneAlt} from "react-icons/all";

class Footer extends Component {
    render() {
        let year = new Date().getFullYear();
        return (
            <footer>
                <div className={"footerContainer"}>
                    <div className={"footerElement"}>
                        <h5>Rólunk</h5>
                        <NavLink to={"/kapcsolat"}>Kapcsolat</NavLink><br/>
                        <NavLink to={"/"}>Csapat</NavLink><br/>
                        <NavLink to={"/adatkezeles"}>Adatkezelés</NavLink><br/>
                    </div>
                    <div className={"footerElement"}>
                        <h5>Szolgáltatásaink</h5>
                        <NavLink to={"/szolgaltatasok"}>Online marketing</NavLink><br/>
                        <NavLink to={"/szolgaltatasok"}>Tartalom gyártás (videó, grafikák)</NavLink><br/>
                        <NavLink to={"/szolgaltatasok"}>Weblap tervezés, készítés</NavLink><br/>
                    </div>
                    <div className={"footerElement"}>
                        <h5>Elérhetőségek</h5>
                        <a href={"tel:+36301234567"}>
                            <FaPhoneAlt/> +36 30 616 0014
                        </a><br/>

                        <a href={"mailto:hello@valiantnetworks.hu"}>
                            <FaEnvelope/> hello@valiantnetworks.hu
                        </a><br/>

                        <a href={"https://www.facebook.com/valiantnetworks/"}>
                            <FaFacebookF/> Facebook oldalunk
                        </a><br/>

                    </div>
                    <div className={"copyright"}>
                        Copyright © {year} Valiant Networks Kft., Készítette: <a
                        href={"https://opthink.com"}>OpThink</a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;