import React, {Component} from 'react';
import './navigation-logo.css';
import logo from '../../assets/logo_horizontal.png';

class HeaderLogo extends Component {

    render() {
        return (
            <div className={"navigation-logo"}>
                <img alt={"logo"} src={logo}/>
            </div>
        );
    }
}

export default HeaderLogo;