import React, {Component} from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import contact_us_png from "../assets/contactus.png";
import EmailValidator from "../utils/EmailValidator";
import {FaEnvelope, FaHandPeace, FaPaperPlane, FaPhoneAlt} from "react-icons/all";
import APICaller from "../utils/APICaller";

const PageWrapper = styled.div`
   width: 100%;
   margin-top: 60px;
   //min-height: calc(100vh - 60px);
`;

const ContactWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  position:relative;
  padding: 50px 0 6px 0;
  
  @media screen and (min-width: 250px) and (max-width: 1200px){
    position: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: center;
    margin: 0;
  }
`;

const ContactTextWrapper = styled.div`
  width: 40%;
  position:relative;
  left: 5%;
  
  h1 {
    font-family: "Open Sans Light", sans-serif;
    font-size: 34pt;
    color: rebeccapurple;
    left:0;
  }
  h3 {
    font-family: "Titillium Web Light", sans-serif;
    font-size: 25pt;
    color: #282c34;
    text-align: left;
    line-height: 1.5em;
  }
  p {
    font-family: "Calibri Light", sans-serif;
    font-size: 18pt;
    color: #282c34;
    text-align: left;
    line-height: 1.3em;
  }
  p > a{
    text-decoration: none;
    font-size: 14pt;
    color: #282c34;
  }
  
  @media screen and (min-width: 250px) and (max-width: 1200px){
    width: 90%;
    left: 0;
    margin: 0 auto;
  }

`;

const ContactFormWrapper = styled.div`
  width: 40%;
  max-width: 600px;
  background-color: #272831;
  color: white;
  padding: 60px;
  
  position:absolute;
  top: 10%;
  right: 5%;
  z-index: 3;
  @media screen and (min-width: 250px) and (max-width: 1200px){
    width: 100%;
    padding: 30px 5%;
    position:inherit;
    right: 0;
    top:0;
    max-width: unset;
  }

`;

const FormTitle = styled.h2`
    font-family: "Tahoma", sans-serif;
    font-size: 28pt ;
    text-align: left;
`;

const Button = styled.div`
    font-size: 16pt;
    color: white;
    padding: 10px 25px;
    margin: 20px auto 5px auto;
    border: 2px solid white;
    border-radius: 30px;
    
    width: fit-content;
    
    &:hover{
      background-color: white;
      color: #71378c;
      cursor: pointer;
    }
`;

const Input = styled(TextField)`
    color: white;
    width: 100%;
    padding: 20px 0;
    
    label{
      color: white;
      font-family: "Segoe UI", sans-serif;
    }
    label.Mui-focused{
      color: #ac3abf;
    }
    
    div>input, div{
      color: white !important;
      height: 40px;
      text-align: left;
      font-family: "Segoe UI", sans-serif;
      font-size: 16pt;
    }
    
    .MuiInput-underline:after{
      border-bottom-color: #ac3abf;
    }
    
    .MuiInput-underline:before{
      border-bottom-color: white;
    }
    
    svg{
      fill: white;
    }
`;

const MultiLineInput = styled(Input)`
  
    div>input, div{
      color: white !important;
      height: 125px;
    }
`;

const Item = styled(MenuItem)`
    color: white;
`;

const DIV = styled.div`
  height: 600px;
  background-image: -webkit-linear-gradient(45deg, #61dafb, rebeccapurple);
  overflow: hidden;
  
  padding: 0 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position:relative;
  
  img{
    height: 100%;
  }
  
  h2{
    font-size: 28pt;
    font-family: "Open Sans Light", sans-serif;
    color: white;
    
    position:absolute;
    right: 15%;
    bottom: 20%;
    @media screen and (min-width: 600px) and (max-width: 1300px){
       font-size: 25pt;
       right: 5%;
       bottom: 25%;
    }
    @media screen and (min-width: 250px) and (max-width: 599px){
       color: #282c34;
       width: 95%;
       margin: 5px auto;
       text-align: center;
       position: unset;
    }
  }
  
    @media screen and (min-width: 250px) and (max-width: 1200px){
      width: 100%;
    }

`;

const topics = [
  {value: '1', label: 'Online marketing',},
  {value: '2', label: 'Webfejlesztés',},
  {value: '3', label: 'Szoftverfejlesztés',},
  {value: '4', label: 'Videó- és képkészítés',},
  {value: '5', label: 'Kreatív szövegírás',},
  {value: '6', label: 'Social Media oldal üzemeltetés',},
  {value: '7', label: 'Egyéb',},
];

export default class Kapcsolat extends Component {

  state = {
    name: "",
    phone: "",
    email: "",
    topic: "",
    idea: "",
  };

  checkInput(value) {
    return value.length < 5 && value.length > 0;
  }

  checkName() {
    return this.state.name.length <= 3;
  }

  checkTel() {
    return this.state.phone.length < 7;
  }

  sendRequest() {
    if (this.checkName() || !EmailValidator(this.state.email) || this.checkTel())
      return;

    let topicName = topics.find(topic => topic.value === this.state.topic).label;

    APICaller.sendContactForm(
      this.state.name,
      this.state.email, this.state.phone,
      this.state.idea, topicName
    );

    this.setState({
      name: '',
      phone: '',
      email: '',
      idea: '',
      topic: '',
    })
  }

  render() {
    return (
      <PageWrapper>
        <ContactWrapper>
          <ContactTextWrapper>
            <h1>Elérhetőségek</h1>
            <h3>
              Felpörgetnéd a marketing csatornáidat, esetleg új ötleted támadt?
            </h3>
            <p>
              Üljünk le egy kávéra, hogy teljes egészében meg tudjuk ismerni egymást és egymás igényeit.
              Folyamatosan elemezzük a piacot, hogy minél érdekesebb és minél nagyobb kihívást rejtő
              projekteken tudjunk dolgozni.
            </p>
            <p>
              <a href={"tel:+36301234567"}>
                <FaPhoneAlt/> +36 30 616 0014
              </a><br/>
              <a href={"mailto:hello@valiantnetworks.hu"}>
                <FaEnvelope/> hello@valiantnetworks.hu
              </a><br/>
            </p>
          </ContactTextWrapper>
          <ContactFormWrapper>
            <FormTitle>Beszélgessünk az új projektedről!</FormTitle>
            <form>
              <Input label={"Teljes név"} id={"name"} required type={"name"}
                     error={this.checkInput(this.state.name)}
                     onChange={(e) => this.setState({name: e.target.value})}
                     value={this.state.name}
              />
              <Input label={"Telefonszám"} id={"phone"} required type={"phone"}
                     error={this.checkInput(this.state.phone)}
                     onChange={(e) => this.setState({phone: e.target.value})}
                     value={this.state.phone}
              />
              <Input label={"E-mail cím"} id={"email"} required type={"email"}
                     error={this.state.email.length > 0 && !EmailValidator(this.state.email)}
                     onChange={(e) => this.setState({email: e.target.value})}
                     value={this.state.email}
              />

              <Input
                id="standard-select-currency"
                select
                label="Téma"
                value={this.state.topic}
                onChange={e => this.setState({topic: e.target.value})}
                //helperText="Téma"
                margin="normal"
              >
                {topics.map(option => (
                  <Item key={option.value} value={option.value}>
                    {option.label}
                  </Item>
                ))}
              </Input>
              <MultiLineInput className={"multiline"} label={"Ötlet részletezése"} id={"idea"} required
                              type={"text"}
                              error={this.checkInput(this.state.idea)} multiline rowsMax="4"
                              onChange={(e) => this.setState({idea: e.target.value})}
                              value={this.state.idea}
              />
              <Button onClick={() => this.sendRequest()}>Küldés <FaPaperPlane/></Button>
            </form>
          </ContactFormWrapper>
          <DIV>
            <img alt={"kapcsolat_kep"} src={contact_us_png}/>
            {/*<h2>Integess nekünk, Ne habozz! <FaHandPeace/></h2>*/}
            <h2>Ne légy félénk, inkább élénk! <FaHandPeace/></h2>
          </DIV>
        </ContactWrapper>

      </PageWrapper>
    );
  }
}
