import React, {Component} from 'react';
import './App.css';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import {polyfill} from 'es6-promise';
import "babel-polyfill";

import MessengerCustomerChat from 'react-messenger-customer-chat';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import Homepage from "./pages/Homepage";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Kapcsolat from "./pages/Kapcsolat";
import ErrorPage from "./pages/ErrorPage";
import SzolgaltatasokPage from "./pages/Szolgaltatasaink";
import ReferenciakPage from "./pages/Referenciak";
import AdatkezelesPage from "./pages/AdatkezelesPage";
import VimeoPlayer from "./components/elements/VimeoPlayer";
import ConfiguredToastContainer from "./utils/ConfiguredToastContainer";

//FontAwesome doesn't work with React Component if you want to use gradient coloring.
// import {library} from '@fortawesome/fontawesome-svg-core';
// import {fab} from '@fortawesome/free-brands-svg-icons';
// import {fas} from '@fortawesome/free-solid-svg-icons';
//
// library.add(fab, fas);

//polyfill-ing other languages (e.g.: es6 can work on ie as well)
polyfill();

class App extends Component {
  componentDidMount() {
    document.title = "Valiant Networks - Megoldás a marketingben."
  }

  render() {
    return (
      <div className="App">
        {/*<div className={'cookies' + (this.state.cookie ? ' hidden' : '')}>*/}
        {/*    Kedves Látogató! Tájékoztatjuk, hogy a honlap felhasználói élmény fokozásának érdekében sütiket*/}
        {/*    alkalmazunk. A honlapunk használatával Ön a tájékoztatásunkat tudomásul veszi. Adatvédelmi*/}
        {/*    tájékoztatás a láblécen keresztül elérhető.*/}
        {/*    <button onClick={() => this.handleCookieBtn()} className={"button_cookies"}*/}
        {/*    >Elfogadom</button>*/}
        {/*</div>*/}
        <BrowserRouter>
          <ScrollToTop>
            <ConfiguredToastContainer/>
            <Header/>
            <VimeoPlayer/>
            <MessengerCustomerChat
              pageId="201795243792772" //496014440765405
              appId="319121685949251" //517155639113804
              htmlRef={window.location.pathname}
            />
            <Switch>
              <Route path={"/"} component={Homepage} exact/>
              <Route path={"/kapcsolat"} component={Kapcsolat} exact/>
              <Route path={"/szolgaltatasok"} component={SzolgaltatasokPage} exact/>
              <Route path={"/referencia"} component={ReferenciakPage} exact/>
              {/*<Route path={"/rolunk"} component={RolunkPage} exact/>*/}
              <Route path={"/adatkezeles"} component={AdatkezelesPage} exact/>
              <Route component={ErrorPage}/>
            </Switch>
            <Footer/>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
